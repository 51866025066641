.fa-spin-fast {
    -webkit-animation: fa-spin-fast 0.2s infinite linear;
    animation: fa-spin-fast 0.2s infinite linear;
}
@-webkit-keyframes fa-spin-fast {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes fa-spin-fast {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.material-card {
    position: relative;
    height: 0;
    padding-bottom: calc(100% - 16px);
    margin-bottom: 6.6em;
}
.material-card h2 {
    position: absolute;
    top: calc(100% - 16px);
    left: 0;
    width: 100%;
    padding: 10px 16px;
    color: #fff;
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 0;
    z-index: 10;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.material-card h2 span {
    display: block;
}
.material-card h2 strong {
    font-weight: 400;
    display: block;
    font-size: .8em;
}
.material-card h2:before,
.material-card h2:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: -16px;
    width: 0;
    border: 8px solid;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.material-card h2:after {
    top: auto;
    bottom: 0;
}
@media screen and (max-width: 767px) {
    .material-card.mc-active {
        padding-bottom: 0;
        height: auto;
    }
}
.material-card.mc-active h2 {
    top: 0;
    padding: 10px 16px 10px 90px;
}
.material-card.mc-active h2:before {
    top: 0;
}
.material-card.mc-active h2:after {
    bottom: -16px;
}
.material-card .mc-content {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 16px;
    left: 16px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.material-card .mc-btn-action {
    position: absolute;
    right: 16px;
    top: 16px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 5px solid;
    width: 54px;
    height: 54px;
    line-height: 44px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 20;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.material-card.mc-active .mc-btn-action {
    top: 65px;
}
.material-card .mc-description {
    position: absolute;
    top: 100%;
    right: 30px;
    left: 30px;
    bottom: 54px;
    overflow: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 1.2s;
    -moz-transition: all 1.2s;
    -ms-transition: all 1.2s;
    -o-transition: all 1.2s;
    transition: all 1.2s;
}
.material-card .mc-footer {
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.material-card .mc-footer h4 {
    position: absolute;
    top: 200px;
    left: 30px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    -webkit-transition: all 1.4s;
    -moz-transition: all 1.4s;
    -ms-transition: all 1.4s;
    -o-transition: all 1.4s;
    transition: all 1.4s;
}
.material-card .mc-footer a {
    display: block;
    float: left;
    position: relative;
    width: 52px;
    height: 52px;
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 28px;
    color: #fff;
    line-height: 52px;
    text-decoration: none;
    top: 200px;
}
.material-card .mc-footer a:nth-child(1) {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.material-card .mc-footer a:nth-child(2) {
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
}
.material-card .mc-footer a:nth-child(3) {
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
}
.material-card .mc-footer a:nth-child(4) {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
}
.material-card .mc-footer a:nth-child(5) {
    -webkit-transition: all 0.9s;
    -moz-transition: all 0.9s;
    -ms-transition: all 0.9s;
    -o-transition: all 0.9s;
    transition: all 0.9s;
}
.material-card .img-container {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.material-card.mc-active .img-container {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    left: 0;
    top: 12px;
    width: 60px;
    height: 60px;
    z-index: 20;
}
.material-card.mc-active .mc-content {
    padding-top: 5.6em;
}
@media screen and (max-width: 767px) {
    .material-card.mc-active .mc-content {
        position: relative;
        margin-right: 16px;
    }
}
.material-card.mc-active .mc-description {
    top: 50px;
    padding-top: 5.6em;
    opacity: 1;
    filter: alpha(opacity=100);
}
@media screen and (max-width: 767px) {
    .material-card.mc-active .mc-description {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        padding: 50px 30px 70px 30px;
        bottom: 0;
    }
}
.material-card.mc-active .mc-footer {
    overflow: visible;
    position: absolute;
    top: calc(100% - 16px);
    left: 16px;
    right: 0;
    height: 82px;
    padding-top: 15px;
    padding-left: 25px;
}
.material-card.mc-active .mc-footer a {
    top: 0;
}
.material-card.mc-active .mc-footer h4 {
    top: -32px;
}
.material-card.Red h2 {
    background-color: #f44336;
}
.material-card.Red h2:after {
    border-top-color: #f44336;
    border-right-color: #f44336;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Red h2:before {
    border-top-color: transparent;
    border-right-color: #b71c1c;
    border-bottom-color: #b71c1c;
    border-left-color: transparent;
}
.material-card.Red.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #f44336;
    border-bottom-color: #f44336;
    border-left-color: transparent;
}
.material-card.Red.mc-active h2:after {
    border-top-color: #b71c1c;
    border-right-color: #b71c1c;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Red .mc-btn-action {
    background-color: #f44336;
}
.material-card.Red .mc-btn-action:hover {
    background-color: #b71c1c;
}
.material-card.Red .mc-footer h4 {
    color: #b71c1c;
}
.material-card.Red .mc-footer a {
    background-color: #b71c1c;
}
.material-card.Red.mc-active .mc-content {
    background-color: #ffebee;
}
.material-card.Red.mc-active .mc-footer {
    background-color: #ffcdd2;
}
.material-card.Red.mc-active .mc-btn-action {
    border-color: #ffebee;
}
.material-card.Blue-Grey h2 {
    background-color: #607d8b;
}
.material-card.Blue-Grey h2:after {
    border-top-color: #607d8b;
    border-right-color: #607d8b;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Blue-Grey h2:before {
    border-top-color: transparent;
    border-right-color: #263238;
    border-bottom-color: #263238;
    border-left-color: transparent;
}
.material-card.Blue-Grey.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #607d8b;
    border-bottom-color: #607d8b;
    border-left-color: transparent;
}
.material-card.Blue-Grey.mc-active h2:after {
    border-top-color: #263238;
    border-right-color: #263238;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Blue-Grey .mc-btn-action {
    background-color: #607d8b;
}
.material-card.Blue-Grey .mc-btn-action:hover {
    background-color: #263238;
}
.material-card.Blue-Grey .mc-footer h4 {
    color: #263238;
}
.material-card.Blue-Grey .mc-footer a {
    background-color: #263238;
}
.material-card.Blue-Grey.mc-active .mc-content {
    background-color: #eceff1;
}
.material-card.Blue-Grey.mc-active .mc-footer {
    background-color: #cfd8dc;
}
.material-card.Blue-Grey.mc-active .mc-btn-action {
    border-color: #eceff1;
}
.material-card.Pink h2 {
    background-color: #e91e63;
}
.material-card.Pink h2:after {
    border-top-color: #e91e63;
    border-right-color: #e91e63;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Pink h2:before {
    border-top-color: transparent;
    border-right-color: #880e4f;
    border-bottom-color: #880e4f;
    border-left-color: transparent;
}
.material-card.Pink.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #e91e63;
    border-bottom-color: #e91e63;
    border-left-color: transparent;
}
.material-card.Pink.mc-active h2:after {
    border-top-color: #880e4f;
    border-right-color: #880e4f;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Pink .mc-btn-action {
    background-color: #e91e63;
}
.material-card.Pink .mc-btn-action:hover {
    background-color: #880e4f;
}
.material-card.Pink .mc-footer h4 {
    color: #880e4f;
}
.material-card.Pink .mc-footer a {
    background-color: #880e4f;
}
.material-card.Pink.mc-active .mc-content {
    background-color: #fce4ec;
}
.material-card.Pink.mc-active .mc-footer {
    background-color: #f8bbd0;
}
.material-card.Pink.mc-active .mc-btn-action {
    border-color: #fce4ec;
}
.material-card.Purple h2 {
    background-color: #9c27b0;
}
.material-card.Purple h2:after {
    border-top-color: #9c27b0;
    border-right-color: #9c27b0;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Purple h2:before {
    border-top-color: transparent;
    border-right-color: #4a148c;
    border-bottom-color: #4a148c;
    border-left-color: transparent;
}
.material-card.Purple.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #9c27b0;
    border-bottom-color: #9c27b0;
    border-left-color: transparent;
}
.material-card.Purple.mc-active h2:after {
    border-top-color: #4a148c;
    border-right-color: #4a148c;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Purple .mc-btn-action {
    background-color: #9c27b0;
}
.material-card.Purple .mc-btn-action:hover {
    background-color: #4a148c;
}
.material-card.Purple .mc-footer h4 {
    color: #4a148c;
}
.material-card.Purple .mc-footer a {
    background-color: #4a148c;
}
.material-card.Purple.mc-active .mc-content {
    background-color: #f3e5f5;
}
.material-card.Purple.mc-active .mc-footer {
    background-color: #e1bee7;
}
.material-card.Purple.mc-active .mc-btn-action {
    border-color: #f3e5f5;
}
.material-card.Deep-Purple h2 {
    background-color: #673ab7;
}
.material-card.Deep-Purple h2:after {
    border-top-color: #673ab7;
    border-right-color: #673ab7;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Deep-Purple h2:before {
    border-top-color: transparent;
    border-right-color: #311b92;
    border-bottom-color: #311b92;
    border-left-color: transparent;
}
.material-card.Deep-Purple.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #673ab7;
    border-bottom-color: #673ab7;
    border-left-color: transparent;
}
.material-card.Deep-Purple.mc-active h2:after {
    border-top-color: #311b92;
    border-right-color: #311b92;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Deep-Purple .mc-btn-action {
    background-color: #673ab7;
}
.material-card.Deep-Purple .mc-btn-action:hover {
    background-color: #311b92;
}
.material-card.Deep-Purple .mc-footer h4 {
    color: #311b92;
}
.material-card.Deep-Purple .mc-footer a {
    background-color: #311b92;
}
.material-card.Deep-Purple.mc-active .mc-content {
    background-color: #ede7f6;
}
.material-card.Deep-Purple.mc-active .mc-footer {
    background-color: #d1c4e9;
}
.material-card.Deep-Purple.mc-active .mc-btn-action {
    border-color: #ede7f6;
}
.material-card.Indigo h2 {
    background-color: #3f51b5;
}
.material-card.Indigo h2:after {
    border-top-color: #3f51b5;
    border-right-color: #3f51b5;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Indigo h2:before {
    border-top-color: transparent;
    border-right-color: #1a237e;
    border-bottom-color: #1a237e;
    border-left-color: transparent;
}
.material-card.Indigo.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #3f51b5;
    border-bottom-color: #3f51b5;
    border-left-color: transparent;
}
.material-card.Indigo.mc-active h2:after {
    border-top-color: #1a237e;
    border-right-color: #1a237e;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Indigo .mc-btn-action {
    background-color: #3f51b5;
}
.material-card.Indigo .mc-btn-action:hover {
    background-color: #1a237e;
}
.material-card.Indigo .mc-footer h4 {
    color: #1a237e;
}
.material-card.Indigo .mc-footer a {
    background-color: #1a237e;
}
.material-card.Indigo.mc-active .mc-content {
    background-color: #e8eaf6;
}
.material-card.Indigo.mc-active .mc-footer {
    background-color: #c5cae9;
}
.material-card.Indigo.mc-active .mc-btn-action {
    border-color: #e8eaf6;
}
.material-card.Blue h2 {
    background-color: #2196f3;
}
.material-card.Blue h2:after {
    border-top-color: #2196f3;
    border-right-color: #2196f3;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Blue h2:before {
    border-top-color: transparent;
    border-right-color: #0d47a1;
    border-bottom-color: #0d47a1;
    border-left-color: transparent;
}
.material-card.Blue.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #2196f3;
    border-bottom-color: #2196f3;
    border-left-color: transparent;
}
.material-card.Blue.mc-active h2:after {
    border-top-color: #0d47a1;
    border-right-color: #0d47a1;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Blue .mc-btn-action {
    background-color: #2196f3;
}
.material-card.Blue .mc-btn-action:hover {
    background-color: #0d47a1;
}
.material-card.Blue .mc-footer h4 {
    color: #0d47a1;
}
.material-card.Blue .mc-footer a {
    background-color: #0d47a1;
}
.material-card.Blue.mc-active .mc-content {
    background-color: #e3f2fd;
}
.material-card.Blue.mc-active .mc-footer {
    background-color: #bbdefb;
}
.material-card.Blue.mc-active .mc-btn-action {
    border-color: #e3f2fd;
}
.material-card.Light-Blue h2 {
    background-color: #03a9f4;
}
.material-card.Light-Blue h2:after {
    border-top-color: #03a9f4;
    border-right-color: #03a9f4;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Light-Blue h2:before {
    border-top-color: transparent;
    border-right-color: #01579b;
    border-bottom-color: #01579b;
    border-left-color: transparent;
}
.material-card.Light-Blue.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #03a9f4;
    border-bottom-color: #03a9f4;
    border-left-color: transparent;
}
.material-card.Light-Blue.mc-active h2:after {
    border-top-color: #01579b;
    border-right-color: #01579b;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Light-Blue .mc-btn-action {
    background-color: #03a9f4;
}
.material-card.Light-Blue .mc-btn-action:hover {
    background-color: #01579b;
}
.material-card.Light-Blue .mc-footer h4 {
    color: #01579b;
}
.material-card.Light-Blue .mc-footer a {
    background-color: #01579b;
}
.material-card.Light-Blue.mc-active .mc-content {
    background-color: #e1f5fe;
}
.material-card.Light-Blue.mc-active .mc-footer {
    background-color: #b3e5fc;
}
.material-card.Light-Blue.mc-active .mc-btn-action {
    border-color: #e1f5fe;
}
.material-card.Cyan h2 {
    background-color: #00bcd4;
}
.material-card.Cyan h2:after {
    border-top-color: #00bcd4;
    border-right-color: #00bcd4;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Cyan h2:before {
    border-top-color: transparent;
    border-right-color: #006064;
    border-bottom-color: #006064;
    border-left-color: transparent;
}
.material-card.Cyan.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #00bcd4;
    border-bottom-color: #00bcd4;
    border-left-color: transparent;
}
.material-card.Cyan.mc-active h2:after {
    border-top-color: #006064;
    border-right-color: #006064;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Cyan .mc-btn-action {
    background-color: #00bcd4;
}
.material-card.Cyan .mc-btn-action:hover {
    background-color: #006064;
}
.material-card.Cyan .mc-footer h4 {
    color: #006064;
}
.material-card.Cyan .mc-footer a {
    background-color: #006064;
}
.material-card.Cyan.mc-active .mc-content {
    background-color: #e0f7fa;
}
.material-card.Cyan.mc-active .mc-footer {
    background-color: #b2ebf2;
}
.material-card.Cyan.mc-active .mc-btn-action {
    border-color: #e0f7fa;
}
.material-card.Teal h2 {
    background-color: #009688;
}
.material-card.Teal h2:after {
    border-top-color: #009688;
    border-right-color: #009688;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Teal h2:before {
    border-top-color: transparent;
    border-right-color: #004d40;
    border-bottom-color: #004d40;
    border-left-color: transparent;
}
.material-card.Teal.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #009688;
    border-bottom-color: #009688;
    border-left-color: transparent;
}
.material-card.Teal.mc-active h2:after {
    border-top-color: #004d40;
    border-right-color: #004d40;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Teal .mc-btn-action {
    background-color: #009688;
}
.material-card.Teal .mc-btn-action:hover {
    background-color: #004d40;
}
.material-card.Teal .mc-footer h4 {
    color: #004d40;
}
.material-card.Teal .mc-footer a {
    background-color: #004d40;
}
.material-card.Teal.mc-active .mc-content {
    background-color: #e0f2f1;
}
.material-card.Teal.mc-active .mc-footer {
    background-color: #b2dfdb;
}
.material-card.Teal.mc-active .mc-btn-action {
    border-color: #e0f2f1;
}
.material-card.Green h2 {
    background-color: #4caf50;
}
.material-card.Green h2:after {
    border-top-color: #4caf50;
    border-right-color: #4caf50;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Green h2:before {
    border-top-color: transparent;
    border-right-color: #1b5e20;
    border-bottom-color: #1b5e20;
    border-left-color: transparent;
}
.material-card.Green.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #4caf50;
    border-bottom-color: #4caf50;
    border-left-color: transparent;
}
.material-card.Green.mc-active h2:after {
    border-top-color: #1b5e20;
    border-right-color: #1b5e20;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Green .mc-btn-action {
    background-color: #4caf50;
}
.material-card.Green .mc-btn-action:hover {
    background-color: #1b5e20;
}
.material-card.Green .mc-footer h4 {
    color: #1b5e20;
}
.material-card.Green .mc-footer a {
    background-color: #1b5e20;
}
.material-card.Green.mc-active .mc-content {
    background-color: #e8f5e9;
}
.material-card.Green.mc-active .mc-footer {
    background-color: #c8e6c9;
}
.material-card.Green.mc-active .mc-btn-action {
    border-color: #e8f5e9;
}
.material-card.Light-Green h2 {
    background-color: #8bc34a;
}
.material-card.Light-Green h2:after {
    border-top-color: #8bc34a;
    border-right-color: #8bc34a;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Light-Green h2:before {
    border-top-color: transparent;
    border-right-color: #33691e;
    border-bottom-color: #33691e;
    border-left-color: transparent;
}
.material-card.Light-Green.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #8bc34a;
    border-bottom-color: #8bc34a;
    border-left-color: transparent;
}
.material-card.Light-Green.mc-active h2:after {
    border-top-color: #33691e;
    border-right-color: #33691e;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Light-Green .mc-btn-action {
    background-color: #8bc34a;
}
.material-card.Light-Green .mc-btn-action:hover {
    background-color: #33691e;
}
.material-card.Light-Green .mc-footer h4 {
    color: #33691e;
}
.material-card.Light-Green .mc-footer a {
    background-color: #33691e;
}
.material-card.Light-Green.mc-active .mc-content {
    background-color: #f1f8e9;
}
.material-card.Light-Green.mc-active .mc-footer {
    background-color: #dcedc8;
}
.material-card.Light-Green.mc-active .mc-btn-action {
    border-color: #f1f8e9;
}
.material-card.Lime h2 {
    background-color: #cddc39;
}
.material-card.Lime h2:after {
    border-top-color: #cddc39;
    border-right-color: #cddc39;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Lime h2:before {
    border-top-color: transparent;
    border-right-color: #827717;
    border-bottom-color: #827717;
    border-left-color: transparent;
}
.material-card.Lime.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #cddc39;
    border-bottom-color: #cddc39;
    border-left-color: transparent;
}
.material-card.Lime.mc-active h2:after {
    border-top-color: #827717;
    border-right-color: #827717;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Lime .mc-btn-action {
    background-color: #cddc39;
}
.material-card.Lime .mc-btn-action:hover {
    background-color: #827717;
}
.material-card.Lime .mc-footer h4 {
    color: #827717;
}
.material-card.Lime .mc-footer a {
    background-color: #827717;
}
.material-card.Lime.mc-active .mc-content {
    background-color: #f9fbe7;
}
.material-card.Lime.mc-active .mc-footer {
    background-color: #f0f4c3;
}
.material-card.Lime.mc-active .mc-btn-action {
    border-color: #f9fbe7;
}
.material-card.Yellow h2 {
    background-color: #ffeb3b;
}
.material-card.Yellow h2:after {
    border-top-color: #ffeb3b;
    border-right-color: #ffeb3b;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Yellow h2:before {
    border-top-color: transparent;
    border-right-color: #f57f17;
    border-bottom-color: #f57f17;
    border-left-color: transparent;
}
.material-card.Yellow.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #ffeb3b;
    border-bottom-color: #ffeb3b;
    border-left-color: transparent;
}
.material-card.Yellow.mc-active h2:after {
    border-top-color: #f57f17;
    border-right-color: #f57f17;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Yellow .mc-btn-action {
    background-color: #ffeb3b;
}
.material-card.Yellow .mc-btn-action:hover {
    background-color: #f57f17;
}
.material-card.Yellow .mc-footer h4 {
    color: #f57f17;
}
.material-card.Yellow .mc-footer a {
    background-color: #f57f17;
}
.material-card.Yellow.mc-active .mc-content {
    background-color: #fffde7;
}
.material-card.Yellow.mc-active .mc-footer {
    background-color: #fff9c4;
}
.material-card.Yellow.mc-active .mc-btn-action {
    border-color: #fffde7;
}
.material-card.Amber h2 {
    background-color: #ffc107;
}
.material-card.Amber h2:after {
    border-top-color: #ffc107;
    border-right-color: #ffc107;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Amber h2:before {
    border-top-color: transparent;
    border-right-color: #ff6f00;
    border-bottom-color: #ff6f00;
    border-left-color: transparent;
}
.material-card.Amber.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #ffc107;
    border-bottom-color: #ffc107;
    border-left-color: transparent;
}
.material-card.Amber.mc-active h2:after {
    border-top-color: #ff6f00;
    border-right-color: #ff6f00;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Amber .mc-btn-action {
    background-color: #ffc107;
}
.material-card.Amber .mc-btn-action:hover {
    background-color: #ff6f00;
}
.material-card.Amber .mc-footer h4 {
    color: #ff6f00;
}
.material-card.Amber .mc-footer a {
    background-color: #ff6f00;
}
.material-card.Amber.mc-active .mc-content {
    background-color: #fff8e1;
}
.material-card.Amber.mc-active .mc-footer {
    background-color: #ffecb3;
}
.material-card.Amber.mc-active .mc-btn-action {
    border-color: #fff8e1;
}
.material-card.Orange h2 {
    background-color: #ff9800;
}
.material-card.Orange h2:after {
    border-top-color: #ff9800;
    border-right-color: #ff9800;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Orange h2:before {
    border-top-color: transparent;
    border-right-color: #e65100;
    border-bottom-color: #e65100;
    border-left-color: transparent;
}
.material-card.Orange.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #ff9800;
    border-bottom-color: #ff9800;
    border-left-color: transparent;
}
.material-card.Orange.mc-active h2:after {
    border-top-color: #e65100;
    border-right-color: #e65100;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Orange .mc-btn-action {
    background-color: #ff9800;
}
.material-card.Orange .mc-btn-action:hover {
    background-color: #e65100;
}
.material-card.Orange .mc-footer h4 {
    color: #e65100;
}
.material-card.Orange .mc-footer a {
    background-color: #e65100;
}
.material-card.Orange.mc-active .mc-content {
    background-color: #fff3e0;
}
.material-card.Orange.mc-active .mc-footer {
    background-color: #ffe0b2;
}
.material-card.Orange.mc-active .mc-btn-action {
    border-color: #fff3e0;
}
.material-card.Deep-Orange h2 {
    background-color: #ff5722;
}
.material-card.Deep-Orange h2:after {
    border-top-color: #ff5722;
    border-right-color: #ff5722;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Deep-Orange h2:before {
    border-top-color: transparent;
    border-right-color: #bf360c;
    border-bottom-color: #bf360c;
    border-left-color: transparent;
}
.material-card.Deep-Orange.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #ff5722;
    border-bottom-color: #ff5722;
    border-left-color: transparent;
}
.material-card.Deep-Orange.mc-active h2:after {
    border-top-color: #bf360c;
    border-right-color: #bf360c;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Deep-Orange .mc-btn-action {
    background-color: #ff5722;
}
.material-card.Deep-Orange .mc-btn-action:hover {
    background-color: #bf360c;
}
.material-card.Deep-Orange .mc-footer h4 {
    color: #bf360c;
}
.material-card.Deep-Orange .mc-footer a {
    background-color: #bf360c;
}
.material-card.Deep-Orange.mc-active .mc-content {
    background-color: #fbe9e7;
}
.material-card.Deep-Orange.mc-active .mc-footer {
    background-color: #ffccbc;
}
.material-card.Deep-Orange.mc-active .mc-btn-action {
    border-color: #fbe9e7;
}
.material-card.Brown h2 {
    background-color: #795548;
}
.material-card.Brown h2:after {
    border-top-color: #795548;
    border-right-color: #795548;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Brown h2:before {
    border-top-color: transparent;
    border-right-color: #3e2723;
    border-bottom-color: #3e2723;
    border-left-color: transparent;
}
.material-card.Brown.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #795548;
    border-bottom-color: #795548;
    border-left-color: transparent;
}
.material-card.Brown.mc-active h2:after {
    border-top-color: #3e2723;
    border-right-color: #3e2723;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Brown .mc-btn-action {
    background-color: #795548;
}
.material-card.Brown .mc-btn-action:hover {
    background-color: #3e2723;
}
.material-card.Brown .mc-footer h4 {
    color: #3e2723;
}
.material-card.Brown .mc-footer a {
    background-color: #3e2723;
}
.material-card.Brown.mc-active .mc-content {
    background-color: #efebe9;
}
.material-card.Brown.mc-active .mc-footer {
    background-color: #d7ccc8;
}
.material-card.Brown.mc-active .mc-btn-action {
    border-color: #efebe9;
}
.material-card.Grey h2 {
    background-color: #9e9e9e;
}
.material-card.Grey h2:after {
    border-top-color: #9e9e9e;
    border-right-color: #9e9e9e;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Grey h2:before {
    border-top-color: transparent;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-left-color: transparent;
}
.material-card.Grey.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #9e9e9e;
    border-bottom-color: #9e9e9e;
    border-left-color: transparent;
}
.material-card.Grey.mc-active h2:after {
    border-top-color: #212121;
    border-right-color: #212121;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Grey .mc-btn-action {
    background-color: #9e9e9e;
}
.material-card.Grey .mc-btn-action:hover {
    background-color: #212121;
}
.material-card.Grey .mc-footer h4 {
    color: #212121;
}
.material-card.Grey .mc-footer a {
    background-color: #212121;
}
.material-card.Grey.mc-active .mc-content {
    background-color: #fafafa;
}
.material-card.Grey.mc-active .mc-footer {
    background-color: #f5f5f5;
}
.material-card.Grey.mc-active .mc-btn-action {
    border-color: #fafafa;
}
.material-card.Blue-Grey h2 {
    background-color: #607d8b;
}
.material-card.Blue-Grey h2:after {
    border-top-color: #607d8b;
    border-right-color: #607d8b;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Blue-Grey h2:before {
    border-top-color: transparent;
    border-right-color: #263238;
    border-bottom-color: #263238;
    border-left-color: transparent;
}
.material-card.Blue-Grey.mc-active h2:before {
    border-top-color: transparent;
    border-right-color: #607d8b;
    border-bottom-color: #607d8b;
    border-left-color: transparent;
}
.material-card.Blue-Grey.mc-active h2:after {
    border-top-color: #263238;
    border-right-color: #263238;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.material-card.Blue-Grey .mc-btn-action {
    background-color: #607d8b;
}
.material-card.Blue-Grey .mc-btn-action:hover {
    background-color: #263238;
}
.material-card.Blue-Grey .mc-footer h4 {
    color: #263238;
}
.material-card.Blue-Grey .mc-footer a {
    background-color: #263238;
}
.material-card.Blue-Grey.mc-active .mc-content {
    background-color: #eceff1;
}
.material-card.Blue-Grey.mc-active .mc-footer {
    background-color: #cfd8dc;
}
.material-card.Blue-Grey.mc-active .mc-btn-action {
    border-color: #eceff1;
}
